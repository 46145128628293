import { PollChoice, ServerPoll } from '../data-types';
import { Table, TablePropertyFiltering, TableSorting } from '@amzn/awsui-components-react';

import { ColumnDefinitionWithId } from '../utils/tableUtil';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { available_locale_options } from './multi-select-config';

export function getPollChoice(index: number, poll_choices?: PollChoice[]) {
    // Check if the array has poll choice[index]
    if (!poll_choices || poll_choices.length <= index) {
        return '';
    } else {
        return poll_choices[index]?.value;
    }
}

export interface FanPollTableItem extends ServerPoll, Table.Item {
    // Used to join fields from ServerPoll and Table.Item - no new fields required
}
export const FAN_POLL_COLUMN_ID = {
    id: 'id',
    locale: 'locale',
    association: 'association',
    prompt: 'prompt',
    choice_1: 'choice_1',
    choice_2: 'choice_2',
    choice_3: 'choice_3',
    choice_4: 'choice_4',
    choice_5: 'choice_5',
    start_date: 'start_date',
    expiration_date: 'expiration_date',
    created_at: 'created_at',
    last_updated: 'last_updated',
    title: 'title',
    status: 'status',
};

export const FAN_POLL_COLUMN_DEFINITIONS: ColumnDefinitionWithId<FanPollTableItem>[] = [
    {
        id: FAN_POLL_COLUMN_ID.id,
        header: () => 'ID',
        cell: (item) => (
            <div>
                <Link to={`/fan-polls/${item.id}?locale=${item.locale}`}>{item.id}</Link>
            </div>
        ),
        minWidth: '200px',
    },
    {
        id: FAN_POLL_COLUMN_ID.locale,
        header: () => 'Locale',
        cell: (item) => item.locale,
        minWidth: '100px',
    },
    {
        id: FAN_POLL_COLUMN_ID.association,
        header: () => 'Artist Association',
        cell: (item) => item.association,
        minWidth: '150px',
    },
    {
        id: FAN_POLL_COLUMN_ID.status,
        header: () => 'Status',
        cell: (item) => item.status,
        minWidth: '150px',
    },
    {
        id: FAN_POLL_COLUMN_ID.start_date,
        header: () => 'Start Date',
        cell: (item) => item.eligibility?.schedule?.start_date,
        minWidth: '170px',
    },
    {
        id: FAN_POLL_COLUMN_ID.expiration_date,
        header: () => 'Expiration Date',
        cell: (item) => item.eligibility?.schedule?.expiration_date,
        minWidth: '170px',
    },
    {
        id: FAN_POLL_COLUMN_ID.created_at,
        header: () => 'Creation Date',
        cell: (item) => item.created_at,
        minWidth: '150px',
    },
    {
        id: FAN_POLL_COLUMN_ID.last_updated,
        header: () => 'Last Updated Date',
        cell: (item) => item.last_updated,
        minWidth: '150px',
    },
    {
        id: FAN_POLL_COLUMN_ID.prompt,
        header: () => 'Poll Question',
        cell: (item) => item.prompt?.value,
        minWidth: '200px',
    },
    {
        id: FAN_POLL_COLUMN_ID.choice_1,
        header: () => 'Poll Choice 1',
        cell: (item) => getPollChoice(0, item.choices),
        minWidth: '170px',
    },
    {
        id: FAN_POLL_COLUMN_ID.choice_2,
        header: () => 'Poll Choice 2',
        cell: (item) => getPollChoice(1, item.choices),
        minWidth: '170px',
    },
    {
        id: FAN_POLL_COLUMN_ID.choice_3,
        header: () => 'Poll Choice 3',
        cell: (item) => getPollChoice(2, item.choices),
        minWidth: '170px',
    },
    {
        id: FAN_POLL_COLUMN_ID.choice_4,
        header: () => 'Poll Choice 4',
        cell: (item) => getPollChoice(3, item.choices),
        minWidth: '170px',
    },
    {
        id: FAN_POLL_COLUMN_ID.choice_5,
        header: () => 'Poll Choice 5',
        cell: (item) => getPollChoice(4, item.choices),
        minWidth: '170px',
    },
    {
        id: FAN_POLL_COLUMN_ID.title,
        header: () => 'Poll Title',
        cell: (item) => item.title,
        minWidth: '150px',
    },
];

export const FAN_POLL_SORTABLE_COLUMNS: TableSorting.SortableColumn<FanPollTableItem>[] = [
    { id: FAN_POLL_COLUMN_ID.locale, field: FAN_POLL_COLUMN_ID.locale },
    { id: FAN_POLL_COLUMN_ID.association, field: FAN_POLL_COLUMN_ID.association },
    { id: FAN_POLL_COLUMN_ID.status, field: FAN_POLL_COLUMN_ID.status },
    { id: FAN_POLL_COLUMN_ID.prompt, field: FAN_POLL_COLUMN_ID.prompt },
    { id: FAN_POLL_COLUMN_ID.start_date, field: FAN_POLL_COLUMN_ID.start_date },
    { id: FAN_POLL_COLUMN_ID.expiration_date, field: FAN_POLL_COLUMN_ID.expiration_date },
    { id: FAN_POLL_COLUMN_ID.created_at, field: FAN_POLL_COLUMN_ID.created_at },
    { id: FAN_POLL_COLUMN_ID.last_updated, field: FAN_POLL_COLUMN_ID.last_updated },
    { id: FAN_POLL_COLUMN_ID.title, field: FAN_POLL_COLUMN_ID.title },
];

export const FAN_POLL_CONTENT_SELECTOR_OPTIONS = [
    {
        label: 'Main Properties',
        options: [
            { id: FAN_POLL_COLUMN_ID.id, label: 'ID', editable: false, visible: true },
            { id: FAN_POLL_COLUMN_ID.locale, label: 'Locale', editable: false, visible: true },
            { id: FAN_POLL_COLUMN_ID.association, label: 'Artist Association', editable: true, visible: true },
            { id: FAN_POLL_COLUMN_ID.status, label: 'Status', editable: false, visible: true },
        ],
    },
    {
        label: 'Poll Scheduling',
        options: [
            { id: FAN_POLL_COLUMN_ID.start_date, label: 'Start Date', editable: true, visible: true },
            { id: FAN_POLL_COLUMN_ID.expiration_date, label: 'Expiration Date', editable: true, visible: true },
            { id: FAN_POLL_COLUMN_ID.created_at, label: 'Creation Date', editable: false, visible: true },
            { id: FAN_POLL_COLUMN_ID.last_updated, label: 'Last Updated Date', editable: false, visible: true },
        ],
    },
    {
        label: 'Poll Content',
        options: [
            { id: FAN_POLL_COLUMN_ID.prompt, label: 'Poll Question', editable: true, visible: true },
            { id: FAN_POLL_COLUMN_ID.choice_1, label: 'Poll Choice 1', editable: true, visible: true },
            { id: FAN_POLL_COLUMN_ID.choice_2, label: 'Poll Choice 2', editable: true, visible: true },
            { id: FAN_POLL_COLUMN_ID.choice_3, label: 'Poll Choice 3', editable: true, visible: true },
            { id: FAN_POLL_COLUMN_ID.choice_4, label: 'Poll Choice 4', editable: true, visible: true },
            { id: FAN_POLL_COLUMN_ID.choice_5, label: 'Poll Choice 5', editable: true, visible: true },
            { id: FAN_POLL_COLUMN_ID.title, label: 'Poll Title', editable: true, visible: true },
        ],
    },
];

export const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Polls' },
    { value: 15, label: '15 Polls' },
    { value: 30, label: '30 Polls' },
    { value: 100, label: '100 Polls' },
    { value: 500, label: '500 Polls' },
    { value: 1000, label: '1000 Polls' },
];

export const PROPERTY_FILTERING_OPTIONS: TablePropertyFiltering.Option[] = [
    {
        propertyLabel: 'Locale',
        propertyKey: FAN_POLL_COLUMN_ID.locale,
        groupValuesLabel: 'Locale values',
        values: available_locale_options.map((option) => option.label),
    },
    {
        propertyLabel: 'Status',
        propertyKey: FAN_POLL_COLUMN_ID.status,
        groupValuesLabel: 'Status values',
        values: ['ACTIVE', 'SCHEDULED', 'EXPIRED'],
    },
];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
    placeholder: 'Filter distributions by property or value',
    operationAndText: 'and',
    operationNotAndText: 'and not',
    operationOrText: 'or',
    operationNotOrText: 'or not',
    clearFiltersText: 'Clear filters',
    groupPropertiesText: 'Properties',
    groupValuesText: 'Values',
    filteringEmpty: 'No results found',
    filteringLabel: 'Filter distributions',
    filteringStatusText: 'Loading',
};
